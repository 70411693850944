.vehicle-slider-container {
  .swiper{
    cursor: pointer !important;
    margin-left: 1rem !important;

    .swiper-wrapper {
      gap: 1.5rem !important;
    }

    .css-k008qs, .swiper-slide, .swiper-slide-active {
      max-width: 105px !important;
      
      display: flex !important;
      justify-content: center !important;
    }
  }
}